html {
  scroll-behavior: smooth;
  height: 100%;
  margin: 0;
  padding: 0;
}

a {
  color: white;
  text-decoration: none;
}
a:hover {
  color: white;
  text-decoration: underline;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  overflow-x: hidden;
  background-color: #051B68;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-size: 1vw;
}

@media (max-width: 800px) {
  * {
    font-size: 1.25vw;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

#root {
  min-height: 100vh;
}

:root {
  --inner-page-background-color: #171d24;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "NunitoSans_400";
  src: local("NunitoSans_400"),
    url("./assets/fonts/NunitoSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "NunitoSans_600";
  src: local("NunitoSans_600"),
    url("./assets/fonts/NunitoSans-SemiBold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "NunitoSans_700";
  src: local("NunitoSans_700"),
    url("./assets/fonts/NunitoSans-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "NunitoSans_800";
  src: local("NunitoSans_800"),
    url("./assets/fonts/NunitoSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}
